$(function() {
		$(window).on('scroll', function(){
		$('#scroll-down').hide();

		$('a[rel="external"]').click(function(e){
			e.preventDefault();
			window.open(this.href);
		});
	});
});
